import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Form,
  Button,

} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginUrl } from '../../url';

export default function SignIn() {
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();

  const data = {
    email: email,
    password: password,
  };

  var Msg = ({ closeToast, toastProps }) => (
    <div>
      Something went Wrong
    </div>
  )

  useEffect(() => {
    localStorage.clear();
  }, []);

  function submitRegistration(e) {
    e.preventDefault();

    axios
      .post(loginUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("dataponse");
        showSuccessMessage();
        console.log(res.data.tokens.access);
        localStorage.clear();
        localStorage.setItem('access_token', res.data.tokens.access);
        localStorage.setItem('refresh_token', res.data.tokens.access);
        window.location.href = '/dashboard'
      })
      .catch(err => {
        console.log("error");
        console.log(err)
        if (err.response.data.email) {
          Msg = ({ closeToast, toastProps }) => (
            <div>
              Email Error : {JSON.stringify(err.response.data.email[0])}
            </div>
          )
          showErrorMessage();
        }

        if (err.response.data.password) {
          Msg = ({ closeToast, toastProps }) => (
            <div>
              Password Error : {JSON.stringify(err.response.data.password[0])}
            </div>
          )
          showErrorMessage();
        }
      })
  };

  const showSuccessMessage = () => {
    toast.success('Login Successful!', {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  const showErrorMessage = () => {
    toast(<Msg />)
  };


  return (
    <div className="center">
      <div className='container py-5'>
        <div className='row mt-5'>
          <div className='col-lg-6'>IMG</div>
          <div className='col-lg-6'>
            <div className='vertical-center'>
              <h3 className='pb-3'>Sign IN</h3>
              <Form onSubmit={e => submitRegistration(e)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} required/>
                  <Form.Text className="text-muted">
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} required/>
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
                <div className='py-4'>
                  <p>If you have not created an account yet, then please
                     <a href="/register"> sign up</a> first</p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
