import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subheader from "../../components/Subheader/Subheader";
import CompanyDetails from "./CompanyDetails";
import Orders from "./Orders";
import Users from "./Users";

import Parts from "./Parts";
import Machine from "./Machine";
import Material from "./Material";
import ApiConfig from "./ApiConfig";
import Billing from "./Billing";
import Sales from "./Sales";
import PersonalInfo from "./PersonalInfo";
import Setting from "./Setting";
import Documentation from "./Documentation";
import Help from "./Help";

import AddMachine from "./AddMachine";
import Button from "react-bootstrap/Button";
import { Form, Modal } from "react-bootstrap";
import { authAxios } from "../../utils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Addon from "./Addon";
import  { Navigate } from 'react-router-dom'
import { getCompanyCountFromUser } from '../../url';

function Dashboard() {
  const value = true;
  const [data, set_data] = useState([]);
  const [company_add_show, set_company_add] = useState(false);
  const [company_select_show, set_company_select] = useState(false);

  const [company_name, set_company_name] = useState();
  const [company_name_list, set_company_name_list] = useState(0);
  const handleClose = () => set_company_add(false);
  const handleShowCompanyAdd = () => set_company_add(true);

  const handleShowCompanSelect = () => set_company_select(true);

  const company_name_date = {
    company_name : company_name,
  }

    //change remove this
    useEffect(() => {
      if (localStorage.getItem('access_token') === null) {
          window.location.href = '/sign-in'
      }
      else {
          console.log("check")
      };
  }, []);

  useEffect(() => {
    authAxios
      .get(getCompanyCountFromUser)
      .then(response => {
        response.data.error ? console.log("true") : set_data(response.data.data);
        console.log(response.data.company_count)

        if (localStorage.getItem('company_id') == null) {
          if (response.data.company_count == 0) {
            console.log("check1");
            handleShowCompanyAdd();
          } else {
            handleShowCompanSelect();
          }
        }
        else {
          console.log("already exist")
        }


      })
      .catch(err => {
        console.log(err);
      });

  }, []);


  function submitCompanySelect(e) {
    e.preventDefault();
    console.log("select form submitted", company_name_list)
    if (company_name_list != '0') {
      console.log("company selected")
      localStorage.setItem('company_id', company_name_list);
      // window.location.reload();
      window.location.href = '/dashboard/orders'
      // return <Navigate to='/dashboard/orders'/>
      //save to localstorage
    } else {
      showCompanySelectError();
      // add tostify error insted 
      console.log("Please selected company");
    }
  }

  function submitCompanyAdd(e) {
    e.preventDefault();
    console.log("add form submitted", company_name)
    const url = '/api/data/add-company-name/';
    authAxios
    .post(url, company_name_date)
    .then(response => {
        console.log(response.data);
        window.location.href = '/dashboard'
    })
    .catch(err => {
        console.log(err);
    });  
  }

  const showCompanySelectError = () => {
    toast.error('Please select company!', {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  return (
    <>
      <div className="dashboard d-flex">
        <Sidebar />
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            overflowY: "hidden",
          }}
        >
          <Modal show={company_add_show}>
            <Modal.Header closeButton>
              <Modal.Title>Add Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={e => submitCompanyAdd(e)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="company_name"
                    placeholder="Enter Company name"
                    value={company_name}
                    onChange={(e) => set_company_name(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={company_select_show}>
            <Modal.Header closeButton>
              <Modal.Title>Select Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={e => submitCompanySelect(e)}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Select Company</Form.Label>
                  <Form.Select aria-label="Company select example"
                    value={company_name_list}
                    onChange={e => set_company_name_list(e.target.value)} required>
                    <option value='0'>Select Company </option>
                    {data.map(item => {
                      return (
                        <option key={item.company_id} value={item.company_id}>{item.company_name}</option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>
                <Button variant="primary" type="submit">
                  Save
                </Button>            
              </Form>
            </Modal.Body>
          </Modal>

          <Routes>
            <Route path="company-details" element={<CompanyDetails />} />
            <Route path="orders" element={<Orders />} />
            <Route path="models" element={<Parts />} />
            <Route path="users" element={<Users />} />
            <Route path="machine" element={<Machine />} />
            <Route path="machine/add-machine" element={<AddMachine />} />
            <Route path="material" element={<Material />} />
            <Route path="api-integration" element={<ApiConfig />} />
            <Route path="billing" element={<Billing />} />
            <Route path="sales-dashboard" element={<Sales />} />
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="setting" element={<Setting />} />
            <Route path="documentation" element={<Documentation />} />
            <Route path="help" element={<Help />} />
            <Route path="addon" element={<Addon />} />                    
          </Routes>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Dashboard;
