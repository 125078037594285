import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Dashboard from './pages/Dashboard/Dashboard';
import Main from './pages/Main/Main';

function App() {

  var hours = 1; // to clear the localStorage after 1 hour
  // (if someone want to clear after 8hrs simply change hours=8)
  var now = new Date().getTime();
  var setupTime = localStorage.getItem('setupTime');
  if (setupTime == null) {
    localStorage.setItem('setupTime', now)
  } else {
    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.clear()
      localStorage.setItem('setupTime', now);
    }
  }

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Main />} >
            <Route path='/pricing' />
            <Route path='/our-software' />
            <Route path='/documentation' />
            <Route path='/sign-in' />
            <Route path='/contact-us' />
            <Route path='/terms-and-conditions' />
            <Route path='/under-development' />
            <Route path='/register' />
          </Route>
          <Route path='/dashboard' element={<Dashboard />}>
            <Route path='/dashboard/test' />
            <Route path='/dashboard/orders' />
            <Route path='/dashboard/company-details' />
            <Route path='/dashboard/models' />
            <Route path='/dashboard/users' />
            <Route path='/dashboard/machine' />
            <Route path='/dashboard/machine/add-machine' />
            <Route path='/dashboard/material' />
            <Route path='/dashboard/api-integration' />
            <Route path='/dashboard/billing' />
            <Route path='/dashboard/sales-dashboard' />
            <Route path='/dashboard/personal-info' />
            <Route path='/dashboard/setting' />
            <Route path='/dashboard/documentation' />
            <Route path='/dashboard/help' />
            <Route path='/dashboard/add_machine' />
            <Route path='/dashboard/addon' />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
