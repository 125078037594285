import React from "react";
import { useEffect, useState } from "react";
import Subheader from "../../components/Subheader/Subheader";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const PersonalInfo = () => {
    // change this
    const [last_name, set_last_name] = useState();
    const [email_id, set_email_id] = useState();
    const [first_name, set_first_name] = useState();
    const [designation, set_designation] = useState();
    
    return (
        <>
            <Subheader title="PersonalInfo" />
            <div className="container">
                <h4 className="pb-3">Personal Info</h4>

                <div className="row gap-3">
                    <div className="col">
                        <div className="row">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row"><h5>Your Company</h5></div>
                                    <hr></hr>
                                    <div className="row">
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control type="first_name" placeholder="Enter First name" value={first_name} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Last name" value={last_name} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Designation in Company</Form.Label>
                                                <Form.Control type="text" placeholder="designation" value={designation} />
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Save
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="row">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row"><h5>Change Password</h5></div>
                                    <hr></hr>
                                    <div className="row">
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                                <Form.Label>Email ID</Form.Label>
                                                <Form.Control type="email" placeholder="Enter Email ID" value={email_id} />
                                                <Form.Text className="text-muted">
                                                    Password Reset link will be sent to your email address.
                                                </Form.Text>
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Save
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </>
    );
};
export default PersonalInfo;
