import React from "react";
import { useState, useEffect } from "react";
import Subheader from "../../components/Subheader/Subheader";
import { MdAttachMoney } from "react-icons/md";
import { RiAddFill } from "react-icons/ri";
import { authAxios } from "../../utils";
import { machineListUrl } from '../../url';

const Machine = () => {
    const [data, setData] = useState([]);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        authAxios
            .get(machineListUrl)
            .then(response => {
                console.log(response.data)
                response.data.error ? console.log("true") : setData(response.data.data)
                setData(response.data.data);
                setRecords(response.data.data)
            })
            .catch(err => {
                console.log(err);
            });

    }, []);

    const Filter = (event) => {
        setRecords(data.filter(f => f.machine_name.toLowerCase().includes(event.target.value)));
        console.log("check",data)
    }

    return (
        <>
            <Subheader title="Machine" />
            <div>
                <div className="container py-3 w-90">
                    <h4 className="pb-3">Machines and Materials</h4>
                    
                    <div className="row align-bottom py-2">
                        <div className="col-lg-4"><input type="text" className="form-control" onChange={Filter} placeholder="search"/></div>
                    </div>

                    <div className="row align-bottom py-2">
                        <div className="col-lg-4"><button className="btn btn-primary">Add Machine</button></div>
                        <div className="col-lg-2"><button className="btn"><MdAttachMoney /> Add Machine</button></div>
                        <div className="col-lg-2"><button className="btn"><RiAddFill /> Add Machine</button></div>
                        <div className="col-lg-2"><button className="btn">Add Machine</button></div>
                        <div className="col-lg-2"><button className="btn">Filter</button></div>
                    </div>

                    <div className="row text-center">
                        <table className="table">
                            <thead>
                                <tr className="table">
                                    <th>Sr No</th>
                                    <th>Machine</th>
                                    <th>Process</th>
                                    <th>Status</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>

                            <tbody>
                                {records.map(item => {
                                    return (
                                        <tr>
                                            <td>1</td>
                                            <td>{item.machine_name}</td>
                                            <td>{item.manufacturing_tech['name']}</td>
                                            <td>active</td>
                                            <td><a href="#"><i className="fa fa-edit"></i></a></td>
                                            <td><a href="#"><i className="fa fa-trash" aria-hidden="true"></i></a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Machine;
