import React from "react";
import { useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subheader from "../../components/Subheader/Subheader";

const Help = () => {

    return (
        <>
            <Subheader title="Help" />
            <h1>Help</h1>
        </>
    );
};
export default Help;
