import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subheader from "../../components/Subheader/Subheader";
import { authAxios } from "../../utils";
import { getUserListUrl } from '../../url';

const Users = () => {
    const [data, setData] = useState([]);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        authAxios
            .get(getUserListUrl)
            .then(response => {
                console.log(response.data)
                response.data.error ? console.log("true") : setData(response.data.data)
                setData(response.data.data);
                setRecords(response.data.data)
            })
            .catch(err => {
                console.log(err);
            });

    }, []);

    const Filter = (event) => {
        setRecords(data.filter(f => f.user['email'].toLowerCase().includes(event.target.value)));
    }

    return (
        <>
            <Subheader title="Users Dashboard" />
            <div>
                <div className="container py-3 w-90">
                    <h4 className="pb-3">Users</h4>

                    <div className="row align-bottom py-2">
                        <div className="col-lg-4"><input type="text" className="form-control" onChange={Filter} placeholder="email"/></div>
                    </div>
                    
                    <div className="row text-center">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Username</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Contact No</th>
                                    <th scope="col">Parts Uploaded</th>
                                    <th scope="col">Orders</th>
                                    <th scope="col">Login Count</th>
                                    <th scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {records.map(item => {
                                    return (
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>{item.user['username']}</td>
                                            <td>{item.user['email']}</td>
                                            <td></td>
                                            <td>data 4</td>
                                            <td>data 5</td>
                                            <td>data 6</td>
                                            <td>{item.login_count}</td>
                                            <td>data 8</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Users;
