import React from "react";
import { useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subheader from "../../components/Subheader/Subheader";

const Documentation = () => {

    return (
        <>
        <Subheader title="Documentation" />
        <h1>Documentation</h1>
      </>
    );
};

export default Documentation;
