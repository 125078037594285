import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../HomePage/Home';
import { Navbar, Footer } from '../../components';
import Sidebar from '../../components/Sidebar/Sidebar';
import PricingPage from './PricingPage';
import SignIn from '../Auth/SignIn';
import OurSoftware from './OurSoftware';
import ContactUs from './ContactUs';
import UnderDevelopment from './UnderDevelopment';
import Documentation from './Documentation';
import Register from '../Auth/Register';
import TermsandConditions from './TermsandConditions';

function Main() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/pricing' element={<PricingPage />} />
        <Route path='/our-software' element={<OurSoftware />} />
        <Route path='/documentation' element={<Documentation />} />
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/terms-and-conditions' element={<TermsandConditions />} />
        <Route path='/under-development' element={<UnderDevelopment />} />
        <Route path='/register' element={<Register />} />
        <Route path='/sidebar' element={<Sidebar />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Main;
