// export const baseUrl = 'http://35.154.228.198:8000/';
export const baseUrl = 'https://api.quoteazy.ai:8000/';
// export const baseUrl = 'http://127.0.0.1:8000/';
// export const baseUrl = 'http://192.168.0.116:8000/';
const apiURL = "api";
// import { registerUrl } from '../../url';
export const endpoint = `${baseUrl}${apiURL}`;


export const registerUrl = `${endpoint}/auth/register/`;
export const loginUrl = `${endpoint}/auth/login/`;

export const apiKey = `${endpoint}/data-key/`;
export const getUserEmail = `${endpoint}/get_user_email/`;
export const companyDetails = `${endpoint}/data/company_details/`;
export const yourCompany = `${endpoint}/data/your_company/`;
export const companyContact = `${endpoint}/data/company_contact/`;
export const getCompanyCountFromUser = `${endpoint}/data/get_company_count_from_user/`;
export const addCompanyName = `${endpoint}/data/add-company-name/`;
export const machineListUrl = `${endpoint}/machine_list/`;

export const getUserOrderListUrl = `${endpoint}/data/get-user-order-list/`;
export const getUserDocumentList = `${endpoint}/data/get-user-document-list/`;
export const getUserListUrl = `${endpoint}/data/get-user-list/`;
// export const machineListUrl = `${endpoint}/machine_list/`;