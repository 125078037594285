import React from "react";
import { useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subheader from "../../components/Subheader/Subheader";

const Addon = () => {

    return (
        <>
            <Subheader title="Addons | Features Dashboard" />
            <div>
                <div className="container py-3 w-90">
                    <h4 className="pb-3 pb-5">Addons | Advanced Features</h4>

                    <div className="row text-center">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4">image</div>
                                        <div className="col-lg-8 text-start">
                                            <h5>Deactivate Plan | API</h5>
                                            <hr></hr>
                                            <p>Your company is currently activated. Widget for model uploading is publicly accessible.</p>
                                            <p><button className="btn btn-primary">Deactivate API</button></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4">image</div>
                                        <div className="col-lg-8 text-start">
                                            <h5>Deactivate Plan | API</h5>
                                            <hr></hr>
                                            <p>Your company is currently activated. Widget for model uploading is publicly accessible.</p>
                                            <p><button className="btn btn-primary">Deactivate API</button></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
};
export default Addon;
