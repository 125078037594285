import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBBadge
} from "cdbreact";
import { NavLink } from "react-router-dom";
import { FaFirstOrder, FaHourglassHalf } from "react-icons/fa";

const Sidebar = () => {

  return (
    <div>
      <CDBSidebar
        textColor="#fff"
        backgroundColor="#101522"
      >
        <CDBSidebarHeader
          prefix={
            <i className="fa fa-bars fa-large"></i>
          }
        >
          <a href="/" className="text-decoration-none" style={{ color: "inherit" }}>
            quoteazy.ai
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink to="/dashboard/orders" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="shopping-bag" >
                Orders
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/models" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="th-large" >
                Parts
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/users" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="user" >
                Users
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/machine" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="laptop" >
                Machine
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/api-integration" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="code" >
                API Integration
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/billing" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="credit-card" >
                Plan | Billing
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/sales-dashboard" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem suffix={
                  <CDBBadge color="danger" size="small" borderType="pill">
                    new
                  </CDBBadge>
                } icon="chart-line" >
                Sales-Dashboard
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/personal-info" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="info" >
                Personal Info
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/company-details" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem suffix={
                  <CDBBadge color="danger" size="small" borderType="pill">
                    pro
                  </CDBBadge>
                } icon="sign" >
                Company Details
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/addon" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="sign" >
                Addon
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/setting" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="th" >
                Settings
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/documentation" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="sticky-note" >
                Documentation
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/dashboard/help" className={(navData) => (navData.isActive ? "activeClicked" : 'none')}>
              <CDBSidebarMenuItem icon="phone" >
                Help
              </CDBSidebarMenuItem>
            </NavLink>

          </CDBSidebarMenu>
          <CDBSidebarMenu>
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
}

export default Sidebar;
// icons: tag, coloume, exclamation-circle, table, calculator, sign