import React, { useState, useEffect } from 'react'
import {
    Nav,
    NavbarContainer,
    NavLogo,
    NavIcon,
    HamburgerIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavItemBtn,
    NavBtnLink
} from './Navbar.elements'
import { FaTimes, FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib'
import { Button } from 'react-bootstrap';
import { PopupButton } from "react-calendly";
import logoImage from '../../images/logo-name.png'

function Navbar() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const [homeClick, setHomeClick] = useState(false);
    const [servicesClick, setServicesClick] = useState(false);
    const [productsClick, setProductsClick] = useState(false);

    const handleHomeClick = () => {
        setHomeClick(true);
        setProductsClick(false);
        setServicesClick(false);
    }
    const handleServicesClick = () => {
        setHomeClick(false);
        setProductsClick(false);
        setServicesClick(true);
    }
    const handleProductsClick = () => {
        setHomeClick(false);
        setProductsClick(true);
        setServicesClick(false);
    }

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        // so if the screensize is <= 960px then set button state to false
        if (window.innerwidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton();
    }, [])

    window.addEventListener('resize', showButton);

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav>
                    <NavbarContainer>
                        <NavLogo to='/'>
                            <img height={35} src={logoImage} alt='logo' />
                        </NavLogo>
                        <HamburgerIcon onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </HamburgerIcon>
                        <NavMenu onClick={handleClick} click={click} >
                            <NavItem onClick={handleHomeClick} homeClick={homeClick}>
                                <NavLinks to='/our-software' onClick={closeMobileMenu}>
                                    Our Software
                                </NavLinks>
                            </NavItem>

                            <NavItem onClick={handleServicesClick} servicesClick={servicesClick}>
                                <NavLinks to='/pricing' onClick={closeMobileMenu}>
                                    Pricing
                                </NavLinks>
                            </NavItem>

                            <NavItem onClick={handleProductsClick} productsClick={productsClick}>
                                <NavLinks to='/documentation' onClick={closeMobileMenu}>
                                    Documentation
                                </NavLinks>
                            </NavItem>

                            <NavItem onClick={handleProductsClick} productsClick={productsClick}>
                                <NavLinks to='/contact-us' onClick={closeMobileMenu}>
                                    Contact Us
                                </NavLinks>
                            </NavItem>
                            {localStorage.getItem('access_token') ? <NavItem>
                                <NavLinks to='/dashboard'>
                                    Dashboard
                                </NavLinks>
                            </NavItem>
                                :
                                <NavItemBtn >
                                    <NavBtnLink to='/sign-in'>
                                        <Button onClick={closeMobileMenu} fontBig primary>Sign In</Button>
                                    </NavBtnLink>
                                </NavItemBtn>
                            }
                            <NavItemBtn >
                                {button ? (
                                    <NavBtnLink to='/'>
                                        <PopupButton className='btn btn-primary'
                                            url="https://calendly.com/quoteazy/demo"
                                            rootElement={document.getElementById("root")}
                                            text='Book a Demo'
                                        />
                                    </NavBtnLink>
                                ) : (
                                    <NavBtnLink to='/'>
                                        <Button onClick={closeMobileMenu} fontBig primary>SIGN UP</Button>
                                    </NavBtnLink>
                                )}
                            </NavItemBtn>

                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar
