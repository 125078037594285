import React from "react";
import { useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subheader from "../../components/Subheader/Subheader";

const Material = () => {

    return (
        <>
            <Subheader title="Material" />
            <h1>Material</h1>
        </>
    );
};
export default Material;
