import React, {  } from 'react'
import '../../resources/css/bootstrap.min.css'
import UnderMaintenanceImg from '../../images/under-maintenance.png'

function UnderDevelopment() {
    return (
        <>
<div className="white-background py-5" id="cncmill">
	<div className="container width-80">
		<div className="section-medium text-center side-about-card1">
            <h1>Sorry !!!</h1>
            <h1 className="space-bottom">THIS PAGE IS UNDER MAINTENANCE</h1>
            <img src={UnderMaintenanceImg} alt="under-maintenance"/>		
		</div>
	</div>
</div>
        </>
    );
  }
  
  export default UnderDevelopment;