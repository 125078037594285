import React from "react";
import { useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subheader from "../../components/Subheader/Subheader";

const Setting = () => {
    return (
        <>
            <Subheader title="Setting" />
            <h1>Setting</h1>
        </>
    );
};
export default Setting;
