import React from "react";
import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subheader from "../../components/Subheader/Subheader";
import { authAxios } from "../../utils";
import { apiKey } from '../../url';

const ApiConfig = () => {
    const company_id = localStorage.getItem('company_id');

    const [api_data, setApiData] = useState([]);

    const company_id_data = {
        company_id: company_id,
    };

    const createApi = () => {
        console.log("api create")
        authAxios
            .post(apiKey, company_id_data)
            .then(response => {
                console.log(response.data)
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        authAxios
            .get(apiKey)
            .then(response => {
                response.data.error ? console.log("true") : setApiData(response.data.data)
                console.log(response.data.data)
            })
            .catch(err => {
                console.log(err);
            });
    }, [])

    return (
        <>
            <Subheader title="APIConfig" />
            <div className="container py-3">
                <h4 className="pb-3">Billing</h4>
                <div className="card">
                    <div className="card-body">
                        <h5>Api Integration</h5>
                        <hr></hr>

                        <div className="row text-left">
                            <table className="table-responsive">
                                <thead>
                                    <tr className="table">
                                        <th>API Key</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {api_data.map(item => {
                                        return (
                                            <tr>
                                                <td>{item.hashed_key}</td>
                                                <td>{item.created}</td>
                                                <td>active</td>
                                                <td><a href="#"><i className="fa fa-trash" aria-hidden="true"></i></a></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ApiConfig;
