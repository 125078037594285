import axios from "axios";
import { baseUrl } from "./url";

export const authAxios = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`
  }
});


        // axios
        // .get(url, {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        //     "Content-Type": "multipart/form-data",
        //   },
        // })        
        // console.log('this is:', this);