import React from "react";
import { useState } from "react";
import Subheader from "../../components/Subheader/Subheader";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const Sales = () => {
    // change this
    const [short_comany_name, setshort_company_name] = useState();
    const [website, setwebsite] = useState();
    const [company_name, setcompany_name] = useState();
    const [email, setemail] = useState();

    return (
        <>
            <Subheader title="Sales Dashboard" />
            <div className="container">
                <h4 className="pb-3">Sales Info</h4>

                <div className="row gap-3">
                    <div className="col">
                        <div className="row">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row"><h5>Lead Notifications</h5></div>
                                    <hr></hr>
                                    <div className="row">
                                        <Form>
#
                                            <Button variant="primary" type="submit">
                                                Save
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row"><h5>Email Setting</h5></div>
                                    <hr></hr>
                                    <div className="row">
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                                <Form.Label>Outgoing email address</Form.Label>
                                                <Form.Control type="email" placeholder="Support Email" value={email} />
                                                <Form.Text className="text-muted">
                                                This is the email that the customer will be receiving the emails from. If you want to change this email, you will also need to allow DigiFabster to send emails from this address. This involves providing AWS access to this email address.
                                                </Form.Text>
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Save
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row"><h5>Email Templates</h5></div>
                                    <hr></hr>
                                    <div className="row">
                                        <Form>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Enter code.</Form.Label>
                                                <Form.Control as="textarea" rows={3} />
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Save
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col">
                        <div className="row">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row"><h5>Follow-up Notification</h5></div>
                                    <hr></hr>
                                    <div className="row">
                                        <Form>
#
                                            <Button variant="primary" type="submit">
                                                Save
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row"><h5>PDF document Templates</h5></div>
                                    <hr></hr>
                                    <div className="row">
                                        <Form>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Enter Pdf Templates.</Form.Label>
                                                <Form.Control as="textarea" rows={3} />
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Save
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sales;
