import React from 'react';
import '../../resources/css/bootstrap.min.css'
import UnderDevelopment from './UnderDevelopment';

const Documentation = () => {
    return (
        <>
            <p>
                Edit <code>pages/helloReact.js</code> and save to reload.
            </p>
            <UnderDevelopment />
        </>
    );
}

export default Documentation;            