import React from 'react';
import { InfoSection, Pricing } from '../../components';
import { homeObjOne, homeObjFour } from './Data';
import '../../resources/css/bootstrap.min.css'
import dashboard_1_img from '../../images/dashboard-1-img.jpg'
import dashboard_2_img from '../../images/dashboard-2-img.png'
import flexible_implementation_option_img from '../../images/flexible-implementation-option-img.png'

const Home = () => {
    return (
        <>
            <InfoSection {...homeObjOne} />
            <div className='text-center'>
                <h1><center><b>Why to choose quoteazy.ai ?</b></center></h1>
                <span>quoteazy.ai provides superior, cost-effective tools for automating your CNC and
                    <br />
                    additive manufacturing quotes, orders, and customer interactions.</span>
            </div>

            <div className='pt-5'>
                <div className='container w-75 text-center'>
                    <div className='row py-5'>
                        <div className='col-lg-6'>
                            <h3 className='.font-weight-bold'>
                                <strong>
                                    Fully white-labeled e-commerce solution
                                </strong>
                            </h3>
                            <p className='mt-5'>
                                Provide pricing and take orders 24/7 on your site
                            </p>
                        </div>

                        <div className='col-lg-6'>
                            <img alt='dashboard' width={'100%'} src={dashboard_1_img} />
                        </div>
                    </div>

                    <div className='row py-5'>
                        <div className='col-lg-6'>
                            <img alt='dashboard' width={'100%'} src={dashboard_2_img} />
                        </div>
                        <div className='col-lg-6'>
                            <h3 className='.font-weight-bold'>
                                <strong>
                                    Quick to Implement and Easy to Use
                                </strong>
                            </h3>
                            <p className='mt-5'>
                                Be up and running in just a few hours - utilizing our proprietary Assisted Setup
                            </p>
                        </div>
                    </div>

                    <div className='row py-5'>
                        <div className='col-lg-6'>
                            <h3 className='.font-weight-bold'>
                                <strong>
                                    flexible implementation options
                                </strong>
                            </h3>
                            <p className='mt-5'>
                                #                            </p>
                        </div>

                        <div className='col-lg-6'>
                            <img alt='implementation' width={'100%'} src={flexible_implementation_option_img} />
                        </div>
                    </div>

                </div>
            </div>
            <Pricing />
            <InfoSection {...homeObjFour} />
        </>
    )
}

export default Home;