import React from 'react';
import { Button } from 'react-bootstrap';
import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature
} from './Pricing.elements';

 const Pricing = () => {
  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading className='text-center'>Pick a plan that fits your
            <br/>
quoting needs</PricingHeading>
<span className='text-center'>Our software makes it easy to get accurate quotes out to your customers – without tying up your
<br/> valuable technical personnel. Choose from our quarterly and yearly plans. </span>


          <PricingContainer>
            <PricingCard to='/sign-up'>
              <PricingCardInfo>
                {/* <PricingCardIcon>
                  <GiRock />
                </PricingCardIcon> */}
                <PricingCardPlan>Essential</PricingCardPlan>
                <PricingCardCost>₹3000</PricingCardCost>
                <PricingCardLength>per month--
                  - billed annually
                </PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>1 technology included</PricingCardFeature>
                  <PricingCardFeature>No limit on quotes</PricingCardFeature>
                  <PricingCardFeature>Unlimited active machines</PricingCardFeature>
                  <PricingCardFeature>Instant & Manual RFQ Tools</PricingCardFeature>
                  <PricingCardFeature>simultaneous file uploads</PricingCardFeature>  
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/sign-up'>
              <PricingCardInfo>
                {/* <PricingCardIcon>
                  <GiCrystalBars />
                </PricingCardIcon> */}
                <PricingCardPlan>Growth</PricingCardPlan>
                <PricingCardCost>₹10,000</PricingCardCost>
                <PricingCardLength>per month
                  - billed annually</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Includes all features as in Essential</PricingCardFeature>
                  <PricingCardFeature>1 technology included</PricingCardFeature>
                  <PricingCardFeature>Customer & Order Manager</PricingCardFeature>
                  <PricingCardFeature>Shipping & Tax Automation</PricingCardFeature>
                  <PricingCardFeature>Orders/customers CSV Export</PricingCardFeature>
                  <PricingCardFeature>Additional payment methods support</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/sign-up'>
              <PricingCardInfo>
                {/* <PricingCardIcon>
                  <GiCutDiamond />
                </PricingCardIcon> */}
                <PricingCardPlan>Enterprise</PricingCardPlan>
                <PricingCardCost>contact us</PricingCardCost>
                <PricingCardLength></PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Includes all features as in Growth</PricingCardFeature>
                  <PricingCardFeature>Multi-user access</PricingCardFeature>
                  <PricingCardFeature>Dedicated Account Manager</PricingCardFeature>
                  <PricingCardFeature>Customizable Sales Dashboard</PricingCardFeature>
                  <PricingCardFeature>Purchase order (PO) payment collection automation</PricingCardFeature>
                  <PricingCardFeature>Market and positioning analysis</PricingCardFeature>
                  <PricingCardFeature>Value prop and messaging</PricingCardFeature>
                  <PricingCardFeature>SEO, content, social strategy</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}

export default Pricing