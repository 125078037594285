import React from "react";
import { useEffect, useState } from "react";
import Subheader from "../../components/Subheader/Subheader";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import TimezoneSelect from 'react-timezone-select'
import { authAxios } from "../../utils";
import { companyDetails,yourCompany,companyContact } from '../../url';

const CompanyDetails = () => {
  const company_id = localStorage.getItem('company_id');
  // change this
  const [company_detail, set_company_detail] = useState([]);
  const [company_name, setcompany_name] = useState();
  const [short_company_name, setshort_company_name] = useState();
  const [company_website, setcompany_website] = useState();
  
  const your_company_data = {
    company_id:company_id,
    company_name: company_name,
    short_company_name: short_company_name,
    company_website: company_website,
  };

  const [phone, setphone] = useState();
  const [support_email, setsupport_email] = useState();
  const [order_emails, setorder_emails] = useState();
  const [outgoing_emails, setoutgoing_emails] = useState();
  // add timezone usestate

  const company_contact_data = {
    
    phone:phone,
    support_email:support_email,
    order_emails:support_email,
    outgoing_emails:outgoing_emails,
    // add time zone
  }

  const [minimum_order_value, set_minimum_order_value] = useState();

  // const order_and_payment_details = {

  // }

  useEffect(() => {
    authAxios
      .get(companyDetails)
      .then(response => {
        console.log(response.data.data)
        set_company_detail(response.data.data)
      })
      .catch(err => {
        console.log(err);
      });
  }, [])

  const sendYourCompany = (e) => {
    e.preventDefault();
    authAxios
    .post(yourCompany, your_company_data)
    .then(response => {
        console.log(response.data);
        //check and send response
    })
    .catch(err => {
        console.log(err);
        //check and send response
    });    
  };


  const sendCompanyContactData = (e) => {
    e.preventDefault();
    authAxios
    .post(companyContact, company_contact_data)
    .then(response => {
        console.log(response.data);
        //check and send response
    })
    .catch(err => {
        console.log(err);
        //check and send response
    });    
  };

  return (
    <>
      <Subheader title="CompanyDetails" />

      <div className="container">
        <h4 className="pb-3">Company Details</h4>

        <div className="row gap-3">
          <div className="col">
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <div className="row"><h5>Your Company</h5></div>
                  {company_detail.map(item => {
                                    return (
                                      <p key={company_detail.company_name}>{company_detail.company_name}</p>
                                    )
                                })}                  
                  <hr></hr>
                  <div className="row">
                    <Form onSubmit={sendYourCompany}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="company_name" placeholder="Enter Company name" value={company_detail.company_name} onChange={e => setcompany_name(e.target.value)} required/>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicUsername">
                        <Form.Label>Short Company Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter short company name" value={short_company_name} onChange={e => setshort_company_name(e.target.value)} required/>
                        <Form.Text className="text-muted">
                          Any update of company’s short name will result in updated widget URL. Please make sure you change the widget URL on your company_website after you save the new value. Contact us if you need any help.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Company website</Form.Label>
                        <Form.Control type="text" placeholder="Company website" value={company_website} onChange={e => setcompany_website(e.target.value)} required/>
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        Save
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-4">
              <div className="card">
                <div className="card-body">
                  <div className="row"><h5>Invoice Details</h5></div>
                  <hr></hr>
                  <div className="row">
                    #details here
                    <p className="d-flex justify-content-end"><button className="btn btn-primary">Edit</button></p>

                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-4">
              <div className="card">
                <div className="card-body">
                  <div className="row"><h5>Company Terms and Conditions</h5></div>
                  <hr></hr>
                  <div className="row">
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Enter Terms & Conditions.</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                      </Form.Group>
                    </Form>
                    <p className="d-flex justify-content-end"><button className="btn btn-primary">Edit</button></p>

                  </div>
                </div>
              </div>
            </div>
            #add other from models
          </div>

          <div className="col">
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <div className="row"><h5>Company Contact</h5></div>
                  <hr></hr>
                  <div className="row">
                    <Form onSubmit={sendCompanyContactData}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="number" placeholder="Phone Number" value={phone} onChange={e => setphone(e.target.value)} required/>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicUsername">
                        <Form.Label>Support Email</Form.Label>
                        <Form.Control type="email" placeholder="Support Email" value={support_email} onChange={e => setsupport_email(e.target.value)} required/>
                        <Form.Text className="text-muted">
                          This is the email that will display in the email as the way for customers to reach out for help.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Emails for order notifications</Form.Label>
                        <Form.Control type="email" placeholder="Emails for order notifications" value={order_emails} onChange={e => setorder_emails(e.target.value)} required/>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Outgoing email address</Form.Label>
                        <Form.Control type="email" placeholder="Outgoing email address" value={outgoing_emails} onChange={e => setoutgoing_emails(e.target.value)} required/>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Timezone</Form.Label>
                        <Form.Select aria-label="Default select example">
                        </Form.Select>
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        Save
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-4">
              <div className="card">
                <div className="card-body">
                  <div className="row"><h5>Customer Orders and Payment Details</h5></div>
                  <hr></hr>
                  <div className="row">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Currency</Form.Label>
                        <Form.Select aria-label="Default select example">
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicUsername">
                        <Form.Label>Default scale units</Form.Label>
                        <Form.Select aria-label="Default select example">
                        </Form.Select>
                        <Form.Text className="text-muted">
                          select units from drop down.
                        </Form.Text>
                      </Form.Group>
                      # add other from models
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Minimun Order Value</Form.Label>
                        <Form.Control type="number" placeholder="Minimun Order Value" value={minimum_order_value} onChange={e => set_minimum_order_value(e.target.value)} required/>
                      </Form.Group>

                      <Button variant="primary" type="submit">
                        Save
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
export default CompanyDetails;
