import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subheader from "../../components/Subheader/Subheader";
import { authAxios } from "../../utils";
import { getUserOrderListUrl } from '../../url';

const Orders = () => {

    const company_id = localStorage.getItem('company_id');
        
    const company_data = {
        company_id:company_id,
      };

    const url = `${getUserOrderListUrl}?company_id=${localStorage.getItem("company_id")}`;

    const [data, setData] = useState([]);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        authAxios
            .get(url)
            .then(response => {
                console.log(response.data)
                response.data.error ? console.log("true") : setData(response.data.data)
                setData(response.data.data);
                setRecords(response.data.data)
            })
            .catch(err => {
                console.log(err);
            });

    }, []);

    const Filter = (event) => {
        setRecords(data.filter(f => f.order_id.toLowerCase().includes(event.target.value)));
    }

    return (
        <>
        <Subheader title="Order Dashboard" />
            <div>
                <div className="container py-3 w-90">
                    <h4 className="pb-3">Orders</h4>

                    <div className="row align-bottom py-2">
                        <div className="col-lg-4"><input type="text" className="form-control" onChange={Filter} placeholder="order id"/></div>
                    </div>

                    <div className="row align-bottom py-2">
                        <div className="col-lg-4"><button className="btn btn-primary">Add Order</button></div>
                    </div>

                    <div className="row text-center">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">ID</th>
                                    <th scope="col">Created</th>
                                    <th scope="col">Placed</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Paid</th>
                                    <th scope="col">Max Priority</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                            {records.map(item => {
                                    return (                                
                                <tr>
                                    <th scope="row">1</th>
                                    <td>{item.order_id}</td>
                                    <td>data 2</td>
                                    <td>data 3</td>
                                    <td>{item.user['username']}</td>
                                    <td>{item.user['email']}</td>
                                    <td>data 6</td>
                                    <td>data 7</td>
                                    <td>data 8</td>
                                    <td>data 9</td>
                                </tr>
                                    )
                                })}                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Orders;
