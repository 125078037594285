import React, { useRef } from 'react';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

export const ContactUs = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ir6wawn', 'template_6t282ql', form.current, 'HMH9o7eiwCYr-cWFB')
            .then((result) => {
                setShow(true);
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <>
            <div className='text-center pt-5'>
                <h1><center><b>Get in touch</b></center></h1>
                <span>mail us on <a href='mailto:contact@quoteazy.ai' >contact@quoteazy.ai</a>
                    </span>
            </div>
            <div className='py-5'>
                <div className='container w-50'>
                    <Form ref={form} onSubmit={sendEmail}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control name="user_name" type="text" placeholder="Enter Name" />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control name="user_email" type="email" placeholder="Enter email" />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label name="message">Message</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>

                        <Button variant="primary" value="Send" type="submit">
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Thank you for reaching out!!!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Our team will contact you soon.</Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button> */}
                    <Button variant="primary" onClick={handleClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ContactUs;