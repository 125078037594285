import React from 'react';
import { useEffect, useState } from "react";
import '../../resources/css/bootstrap.min.css'
import img1 from '../../images/svg-1.svg'
import gear_manufacturing_img from '../../images/gear-manufacturing-img.png'
import d_viewer_img from '../../images/3d-viewer-img.png'
import { PopupButton } from "react-calendly";
import dashboard_1_img from '../../images/dashboard-1-img.jpg'
import dashboard_2_img from '../../images/dashboard-2-img.png'
import axios from 'axios';
import { authAxios } from "../../utils";

function OurSoftware() {
    const [message, setMessage] = useState('');

    // check
    const url = '/api/get_user_email/';
    const handleClick = () => {
        authAxios
        .get(url)
        .then(res => {
            console.log(res);
        })
        .catch(err => {
            console.log(err);
        });

    };

    return (
        <>
            <div className='text-center py-5'>
                <h1>Features</h1>
                <span>quoteazy.ai provides superior, cost-effective tools for automating your CNC and
                    <br />
                    additive manufacturing quotes, orders, and customer interactions.</span>
            </div>

            <div className='pt-5'>
                <div className='container w-75 text-center'>
                    <div className='row py-5'>
                        <div className='col-lg-6'>
                            <h3 className='.font-weight-bold'>
                                <strong>
                                    Instant Quoting & eCommerce Self-Checkout
                                </strong>
                            </h3>
                            <p className='mt-5'>
                                Use as an internal-only quoting automation tool or as a
                                fully self-service e-commerce solution, blow your customers
                                away with your ability to turn around RFQs in minutes not days.
                                We offer flexible and scalable implementations to best align
                                with your business.
                            </p>
                        </div>

                        <div className='col-lg-6'>
                            <img alt='dashboard' width={'100%'} src={dashboard_1_img} />
                        </div>
                    </div>

                    <div className='row py-5'>

                        <div className='col-lg-6'>
                            <img alt='gear-manufacturing' width={'100%'} src={gear_manufacturing_img} />
                        </div>

                        <div className='col-lg-6'>
                            <h3 className='.font-weight-bold'>
                                <strong>
                                    Live Manufacturing Feedback
                                </strong>
                            </h3>
                            <p className='mt-5'>
                                With CAD files, there's lots of room for error. You have to ensure wall thickness, holes, traps, radii, reclamping and unreachable pockets are all identified and accounted for and we have you covered.
                                quoteazy.ai uses data science to analyze the geometry of your customer files, giving you, and them, instant feedback on manufacturability, while also identifying limitations and suggesting best practices to fix.
                                We accomplish this by displaying a built-in 3D preview with rotation, zooming, and the ability to change orientation, highlighting issues with the 3D model in real-time.
                            </p>
                        </div>
                    </div>

                    <div className='row py-5'>
                        <div className='col-lg-6'>
                            <h3 className='.font-weight-bold'>
                                <strong>
                                    Real-time Healing And Repairing
                                </strong>
                            </h3>
                            <p className='mt-5'>
                                #
                            </p>
                        </div>

                        <div className='col-lg-6'>
                            <img alt='dashboard' width={'100%'} src={dashboard_2_img} />
                        </div>
                    </div>

                    <div className='row py-5'>

                        <div className='col-lg-6'>
                            <img alt='viewer' width={'100%'} src={d_viewer_img} />
                        </div>

                        <div className='col-lg-6'>
                            <h3 className='.font-weight-bold'>
                                <strong>
                                    3D CAD File Viewer
                                </strong>
                            </h3>
                            <p className='mt-5'>
                                We allow customers to change the orientation of their 3D models, reducing the number of errors. This leads to a higher order conversion rate and customer confidence.
                            </p>
                        </div>
                    </div>

                    <div className='row py-5'>
                        <div className='col-lg-6'>
                            <h3 className='.font-weight-bold'>
                                <strong>
                                    Customer & Order Manager (CRM)
                                </strong>
                            </h3>
                            <p className='mt-5'>
                                quoteazy.ai’s CRM functionality is specifically designed to address the needs of manufacturers by manufacturers, enabling you to easily manage customer requests from quote to order and beyond. Grow your business by knowing your business with our easy to implement and use CRM solution.
                            </p>
                        </div>

                        <div className='col-lg-6'>
                            <img alt="printing" width={'75%'} src={img1} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container text-center py-5 my-5'>
                <div className="row align-items-start">
                    <div className="col">
                        <h3>If you’re ready to jump in,
                            <br />let's start</h3>
                        <p> </p>
                        <PopupButton className='btn btn-primary'
                            url="https://calendly.com/quoteazy/demo"
                            /*
                             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                             */
                            rootElement={document.getElementById("root")}
                            text='SCHEDULE A DEMO'
                        />
                        <p> </p>
                        <p>Get a personalized demo and 7-day free trial</p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default OurSoftware;