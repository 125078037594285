import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subheader from "../../components/Subheader/Subheader";
import { authAxios } from "../../utils";
import { getUserDocumentList } from '../../url';

const Parts = () => {

    const company_id = localStorage.getItem('company_id');
        
    const company_data = {
        company_id:company_id,
      };

    const url = `${getUserDocumentList}?company_id=${localStorage.getItem("company_id")}`;

    const [data, setData] = useState([]);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        authAxios
            .get(url)
            .then(response => {
                console.log(response.data)
                response.data.error ? console.log("true") : setData(response.data.data)
                setData(response.data.data);
                setRecords(response.data.data)
            })
            .catch(err => {
                console.log(err);
            });

    }, []);

    const Filter = (event) => {
        setRecords(data.filter(f => f.part_id.toLowerCase().includes(event.target.value)));
    }

    return (
        <>  
            <Subheader title="Parts Dashboard" />
            <div>
                <div className="container py-3 w-90">
                    <h4 className="pb-3">Models</h4>
                    
                    <div className="row align-bottom py-2">
                        <div className="col-lg-4"><input type="text" className="form-control" onChange={Filter} placeholder="partid"/></div>
                    </div>

                    <div className="row text-center">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Model ID</th>
                                    <th scope="col">File Name</th>
                                    <th scope="col">User Email</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Download</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {records.map(item => {
                                    return (
                                        <tr>
                                            <td>1</td>
                                            <td>{item.part_id}</td>
                                            <td>{item.document}</td>
                                            <td>active</td>
                                            <td><a href="#"><i className="fa fa-edit"></i></a></td>
                                            <td><a href="#"><i className="fa fa-trash" aria-hidden="true"></i></a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Parts;
