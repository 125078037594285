import React from "react";
import { useEffect, useState } from "react";
import Subheader from "../../components/Subheader/Subheader";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { authAxios } from "../../utils";
import { getUserEmail } from '../../url';

const Billing = () => {

    const [city, setcity] = useState();
    const handleClick = () => {
        authAxios
        .get(getUserEmail)
        .then(res => {
            //data= plan
            console.log(res);
        })
        .catch(err => {
            console.log(err);
        });

    };

    return (
        <>
            <Subheader className="w-100" title="Billing Dashboard" />
            <div>
                <div className="container py-3">
                    <h4>Billing</h4>

                    <div className="pt-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row"><h5>Trail Plan</h5></div>
                                <hr></hr>
                                <div className="row">
                                    <p>Your company is currently activated. Widget for model uploading is publicly accessible.</p>
                                    <p><button className="btn btn-primary">Deactivate API</button></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row"><h5>Plan and Payments</h5></div>
                                <hr></hr>
                                <div className="row">
                                    <div className="col-lg-2">Your Plan:</div>
                                    <div className="col-lg-3">
                                        <p>[Plan Name]</p>
                                        <p><button className="btn btn-primary">Change Plan</button></p>
                                    </div>
                                    <div className="col-lg-3">
                                        <p>
                                            <span className="fw-bold">Date of renewal:</span>
                                            <span>[Date]</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-3">
                                        <p>
                                            <span className="fw-bold">Payment due:</span>
                                            <span>[price]</span>
                                        </p>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="row">
                                    <div className="col-lg-2">Payment Details:</div>
                                    <div className="col-lg-3">
                                        <p>[P]</p>
                                        <p><button className="btn btn-primary">Change Plan</button></p>
                                    </div>
                                    <div className="col-lg-3">
                                        <p>
                                            <span className="fw-bold">Date of renewal:</span>
                                            <span>[Date]</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row"><h5>Deactivate Plan | API</h5></div>
                                <hr></hr>
                                <div className="row">
                                    <p>Your company is currently activated. Widget for model uploading is publicly accessible.</p>
                                    <p><button className="btn btn-primary">Deactivate API</button></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row"><h5>Company Terms and Conditions</h5></div>
                                <hr></hr>
                                <div className="row">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Enter Terms & Conditions.</Form.Label>
                                            <Form.Control as="textarea" rows={3} />
                                        </Form.Group>
                                    </Form>
                                    <p className="d-flex justify-content-end"><button className="btn btn-primary">Edit</button></p>

                                </div>
                            </div>
                        </div>
                    </div>



                        <div className="pt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row"><h5>Billing Address</h5></div>
                                    <hr></hr>
                                    <div className="row">
                                        <Form>


                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control type="text" placeholder="Your City" value={city} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Street address</Form.Label>
                                                <Form.Control type="text" placeholder="" value={city} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Apartment</Form.Label>
                                                <Form.Control type="text" placeholder="" value={city} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Postal Code</Form.Label>
                                                <Form.Control type="text" placeholder="" value={city} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Sales tax number</Form.Label>
                                                <Form.Control type="text" placeholder="" value={city} />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Select aria-label="Default select example">
                                                    <option value="" >Country</option>
                                                    <option value="AF" >Afghanistan</option>
                                                    <option value="AL" >Albania</option>
                                                    <option value="DZ" >Algeria</option>
                                                    <option value="AD" >Andorra</option>
                                                    <option value="AR" >Argentina</option>
                                                    <option value="AO" >Angola</option>
                                                    <option value="AI" >Anguilla</option>
                                                    <option value="AQ" >Antarctica</option>
                                                    <option value="AM" >Armenia</option>
                                                    <option value="AW" >Aruba</option>
                                                    <option value="AU" >Australia</option>
                                                    <option value="AT" >Austria</option>
                                                    <option value="AZ" >Azerbaijan</option>
                                                    <option value="BD" >Bangladesh</option>
                                                    <option value="BB" >Barbados</option>
                                                    <option value="BY" >Belarus</option>
                                                    <option value="BE" >Belgium</option>
                                                    <option value="BZ" >Belize</option>
                                                    <option value="BJ" >Benin</option>
                                                    <option value="BM" >Bermuda</option>
                                                    <option value="BT" >Bhutan</option>
                                                    <option value="BO" >Bolivia</option>
                                                    <option value="BA" >Bosnia and Herzegovina</option>
                                                    <option value="BW" >Botswana</option>
                                                    <option value="BR" >Brazil</option>
                                                    <option value="BG" >Bulgaria</option>
                                                    <option value="BL" >Burundi</option>
                                                    <option value="CV" >Cabo Verde</option>
                                                    <option value="KH" >Cambodia</option>
                                                    <option value="CA" >Canada</option>
                                                    <option value="CL" >Chile</option>
                                                    <option value="CN" >China</option>
                                                    <option value="CO" >Colombia</option>
                                                    <option value="CD" >Congo</option>
                                                    <option value="CR" >Costa Rica</option>
                                                    <option value="HR" >Croatia</option>
                                                    <option value="CU" >Cuba</option>
                                                    <option value="CY" >Cyprus</option>
                                                    <option value="CZ" >Czech Republic</option>
                                                    <option value="DK" >Denmark</option>
                                                    <option value="DO" >Dominican Republic</option>
                                                    <option value="EC" >Ecuador</option>
                                                    <option value="EG" >Egypt</option>
                                                    <option value="SV" >El Salvador</option>
                                                    <option value="EE" >Estonia</option>
                                                    <option value="ET" >Ethiopia</option>
                                                    <option value="FI" >Finland</option>
                                                    <option value="FR" >France</option>
                                                    <option value="GE" >Georgia</option>
                                                    <option value="DE" >Germany</option>
                                                    <option value="GI" >Gibraltar</option>
                                                    <option value="GR" >Greece</option>
                                                    <option value="GL" >Greenland</option>
                                                    <option value="GP" >Guadeloupe</option>
                                                    <option value="GT" >Guatemala</option>
                                                    <option value="HT" >Haiti</option>
                                                    <option value="HN" >Honduras</option>
                                                    <option value="HK" >Hong Kong</option>
                                                    <option value="HU" >Hungary</option>
                                                    <option value="IS" >Iceland</option>
                                                    <option value="IN" selected>India</option>
                                                    <option value="ID" >Indonesia</option>
                                                    <option value="IR" >Iran</option>
                                                    <option value="IQ" >Iraq</option>
                                                    <option value="IE" >Ireland</option>
                                                    <option value="IL" >Israel</option>
                                                    <option value="IT" >Italy</option>
                                                    <option value="JM" >Jamaica</option>
                                                    <option value="JP" >Japan</option>
                                                    <option value="KZ" >Kazakhstan</option>
                                                    <option value="KE" >Kenya</option>
                                                    <option value="KR" >Korea, Republic of</option>
                                                    <option value="KG" >Kyrgyzstan</option>
                                                    <option value="LV" >Latvia</option>
                                                    <option value="LB" >Lebanon</option>
                                                    <option value="LS" >Lesotho</option>
                                                    <option value="LR" >Liberia</option>
                                                    <option value="LY" >Libya</option>
                                                    <option value="LI" >Liechtenstein</option>
                                                    <option value="LT" >Lithuania</option>
                                                    <option value="LU" >Luxembourg</option>
                                                    <option value="MO" >Macao</option>
                                                    <option value="MK" >Macedonia, The Former Yugoslav Republic of</option>
                                                    <option value="MY" >Malaysia</option>
                                                    <option value="MV" >Maldives</option>
                                                    <option value="MT" >Malta</option>
                                                    <option value="MX" >Mexico</option>
                                                    <option value="MD" >Moldova, Republic of</option>
                                                    <option value="MC" >Monaco</option>
                                                    <option value="ME" >Montenegro</option>
                                                    <option value="MA" >Morocco</option>
                                                    <option value="NP" >Nepal</option>
                                                    <option value="NL" >Netherlands</option>
                                                    <option value="NC" >New Caledonia</option>
                                                    <option value="NZ" >New Zealand</option>
                                                    <option value="NI" >Nicaragua</option>
                                                    <option value="NG" >Nigeria</option>
                                                    <option value="NF" >Norfolk Island</option>
                                                    <option value="NO" >Norway</option>
                                                    <option value="PK" >Pakistan</option>
                                                    <option value="PA" >Panama</option>
                                                    <option value="PY" >Paraguay</option>
                                                    <option value="PE" >Peru</option>
                                                    <option value="PH" >Philippines</option>
                                                    <option value="PL" >Poland</option>
                                                    <option value="PT" >Portugal</option>
                                                    <option value="PR" >Puerto Rico</option>
                                                    <option value="RO" >Romania</option>
                                                    <option value="RU" >Russian Federation</option>
                                                    <option value="WS" >Samoa</option>
                                                    <option value="SM" >San Marino</option>
                                                    <option value="SA" >Saudi Arabia</option>
                                                    <option value="CS" >Serbia and Montenegro</option>
                                                    <option value="SG" >Singapore</option>
                                                    <option value="SK" >Slovakia</option>
                                                    <option value="SI" >Slovenia</option>
                                                    <option value="SO" >Somalia</option>
                                                    <option value="ZA" >South Africa</option>
                                                    <option value="ES" >Spain</option>
                                                    <option value="LK" >Sri Lanka</option>
                                                    <option value="SD" >Sudan</option>
                                                    <option value="SE" >Sweden</option>
                                                    <option value="CH" >Switzerland</option>
                                                    <option value="TW" >Taiwan</option>
                                                    <option value="TJ" >Tajikistan</option>
                                                    <option value="TH" >Thailand</option>
                                                    <option value="TO" >Tonga</option>
                                                    <option value="TN" >Tunisia</option>
                                                    <option value="TR" >Turkey</option>
                                                    <option value="TM" >Turkmenistan</option>
                                                    <option value="UG" >Uganda</option>
                                                    <option value="UA" >Ukraine</option>
                                                    <option value="AE" >United Arab Emirates</option>
                                                    <option value="GB" >United Kingdom</option>
                                                    <option value="US" >United States</option>
                                                    <option value="UY" >Uruguay</option>
                                                    <option value="UZ" >Uzbekistan</option>
                                                    <option value="VU" >Vanuatu</option>
                                                    <option value="VE" >Venezuela, Bolivarian Republic of</option>
                                                    <option value="VN" >Vietnam</option>
                                                    <option value="YE" >Yemen</option>
                                                    <option value="ZW" >Zimbabwe</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Button variant="primary" type="submit">
                                                Save
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        #add billing address

                </div>
            </div>
        </>
    );
};
export default Billing;
