export const homeObjOne = {
    primary: true,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'SMART METALWORKING QUOTATIONS IN THE CLOUD',
    headline: 'Manufacturing Shop Software That Helps You Sell More, Instantly',
    description:
      'Provide your CNC machining and additive manufacturing shop with the tools you need to process quotes in minutes, generate instant quotes and orders 24/7 online and simplify getting paid.',
    buttonLabel: 'SCHEDULE A DEMO',
    imgStart: '',
    img: require('../../images/home-page-img-1.png'),
    alt: 'Credit Card',
    start: ''
  };

  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Accelerate Your Online Sales With quoteazy.ai',
    description:
      'Win 3X more quotes by giving your customers online access, allowing them to price work on their own, anytime',
    buttonLabel: 'SCHEDULE A DEMO',
    imgStart: 'start',
    img: require('../../images/sales-icon.png'),
    alt: 'Vault',
    start: 'true'
  };