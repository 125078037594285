import React from 'react';
import '../../resources/css/bootstrap.min.css'
import UnderDevelopment from './UnderDevelopment';

const TermsandConditions = () => {
    return (
        <>
        <div className='py-5'>
            <div className='container w-75'>
                <h1>Terms and Conditions</h1>
                <p>
                Quoteazy.ai a service for automation of order flow for 
                makers and manufacturers (the "Quoteazy.ai Service"). 
                Quoteazy.ai Service is accessible by you and the users 
                of your Web Site through a Quoteazy.ai Widget ("Widget") 
                that you must install on your Web Site. A Widget is a small 
                software module operating within or connecting to the Quoteazy.ai 
                environment and appearing on a Web Site of your designation, 
                which provides you with access to a variety of content and services provided by 
                Quoteazy.ai and its network of job shops and other manufacturers. Quoteazy.ai
                </p>

                <h4>Widget & Launching Code</h4>
                <p>
                The Quoteazy.ai Service will be made available to you and your customers through a Widget that you will be asked to place on your Web Site. You hereby consent to the placement of such widget on your Web Site. You will need to insert an HTML and/or JavaScript code snippet provided by Quoteazy.ai (the "Launching Code") in the HTML code for your Web Site in order to obtain the Widget and launch it on your Web Site. You are granted the non-exclusive, non-transferrable right to insert any such Launching Code in the HTML code for your Web Site for the sole purpose of accessing and launching the Widget on your Web Site pursuant to these Terms and Conditions. You may not use the Launching Code for any other purpose. The Widget and Launching Code provided by Quoteazy.ai under these Terms and Conditions are exclusively owned by Quoteazy.ai Copying, alteration, disassembly, translation, decomplication or reverse engineering of the Widget or Launching Code is strictly prohibited and you will not attempt to discover any source code, underlying ideas, techniques or know-how relating to the widget or Launching Code. You may not modify the Widget or Launching Code. If for any reason whatsoever you do modify the Widget or Launching Code, in addition to the same being a breach of these Terms and Conditions, you recognize that Quoteazy.ai shall be the owner of all rights, including all copyrights, in and to all modifications. You will not remove any copyright, proprietary or licensing notices from the widget or Launching Code provided by Quoteazy.ai You shall not duplicate the Widget or Launching Code and shall not sell, loan, lease or otherwise make the Widget or Launching Code available to any third party.                    
                </p>

                <h4>Account Setup</h4>
                <p>To use the Quoteazy.ai Service, you need to set up an account (the "Account") by registering on the Quoteazy.ai Web Site and installing the Widget by inserting the "Launching Code" in the HTML code for your Website.
When you register an account, we will prompt you for registration information, such as your name, email address, account password, and e-mail address. For any Account we or our billing processors collect your billing information upon initial registration. Your registration information is protected by our Privacy Policy, the most current terms of which are incorporated by reference and which can be found at digifabster.com/privacy.
Please Be Aware That You Must Provide Complete and Accurate Information to Our Payment Processor Or Your Payment May Be Affected. You Must Promptly Update All Information to Keep Your Billing Account Current, Complete and Accurate (Such as A Change In Billing Address, Credit Card Number, Or Credit Card Expiration Date), And You Must Promptly Notify Us Or Our Payment Processor If Your Payment Method Is Cancelled (E.G., For Loss Or Theft) Or If You Become Aware Of A Potential Breach Of Security, Such As The Unauthorized Disclosure Or Use Of Your User Name Or Password. If You Fail to Provide Any Of The Foregoing Information, You Agree That We May Continue Charging You For Any Use Of Paid Services Under Your Billing Account Unless You Have Terminated Your Paid Services.
You further agree (a) not to provide your password to anyone who is not your employee, and (b) to ensure all individuals permitted to use your account information are aware of and have agreed in writing to comply with the terms of these Terms and Conditions. If your purchase includes Technology Set Up Service, Quoteazy.ai support personnel will provide a one-time technology pricing set up based on the data that you provide.
</p>

                <h4>Free Trial Period</h4>
                <p>You shall receive a 7-day free trial period, effective from the date that you set up your Account. During the free trial period you will not be subject to any monthly subscription fees or over-the-limit fees. You must provide valid credit card information immediately after the end of the free trial period in order to continue to use the Quoteazy.ai service.</p>

                <h4>Failed charges</h4>
                <p>It is your explicit responsibility to make the valid card available for automatic charging in accordance with official invoices from Quoteazy.ai Should the transaction fail we shall notify you immediately and allow up to 5 business days to remedy the situation. Any failure of Quoteazy.ai to collect payment from you does not terminate our obligation to make such payment, Quoteazy.ai reserves all rights to claim from you the outstanding payments by any legitimate means.</p>
                <p>Should our auto charging attempts fail during this grace period, your widget and access to Management Console will be blocked without delay. You would then have access only to the Billing section where you can input valid card information in order to continue using our service.</p>

                <h4>Other payment methods</h4>
                <p>By default, you consent to allow Quoteazy.ai to charge your bank card, either directly or through its payment processors. You may also make payments via wire transfer; company checks or other methods explicitly allowed on the Quoteazy.ai web site or agreed with you by Quoteazy.ai. In case you want to pay the invoices by wire transfer, FX wire, ACH or checks, Payment expenses for other payment methods can be set on Quoteazy.ai web site or communicated to you by Quoteazy.ai when you discuss such alternative payment methods.</p>

                <h4>Renewals and Cancellations</h4>
                <p>By default, in order to provide you with uninterrupted and hassle-free service, once you have submitted your payment information, your credit card will be automatically charged for subsequent time periods after the initial period where you authorize payment unless you cancel the account by the end of the then-current Subscription Period. For example, if you have paid for a monthly subscription, after the first month your credit card will be automatically billed at the applicable rate for the following month. By paying for the Quoteazy.ai Service and/or Widget and authorizing payment on your credit card, you authorize us to automatically charge your credit card for any subsequent periods after your initial payment. You explicitly authorize us to charge your credit card without providing additional notice to you after your initial payment. You can cancel your account by contacting customer service with a request in writing (for the avoidance of doubt, such request shall be sent on your letterhead to the contact email at Quoteazy.ai that you will be using). Once your cancellation request is duly submitted, Quoteazy.ai will not be obligated to refund any payment made; any unpaid fees due through the   of your Subscription Term are due immediately. You would be allowed to use the Quoteazy.ai service up till the end of period paid for. Unless request for cancellation is submitted before the last 60 calendar days of the initial and any subsequent Subscription Term, the Subscription Term upon its expiry is automatically extended for a 12-month period, commencing on the first day following the last day of the initial or any subsequent Subscription Term.</p>

                <h4>Upgrades and Downgrades</h4>
                <p>Account Upgrades. You can upgrade your Account at any time by increasing any of the Account Limits. When you upgrade your Account, we will apply the unused portion of your old Subscription Fee to the cost of your upgraded Account.</p>
                <p>Downgrades. You can downgrade your Account by decreasing any of the Account Limits.</p>
                <p>Any upgrade or downgrade shall apply from the next month.</p>
                <p>You acknowledge and accept that downgrading your Account may cause the loss of data, features, or capacity of your account.</p>

                <h4></h4>
                <p></p>
            </div>
        </div>
        </>
    );
}

export default TermsandConditions;            